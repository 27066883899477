<template>
<!-- eslint-disable max-len -->
  <div class="row">
    <div class="col-md-12 col-lg-7 form-panel">
      <div class="row image-row">
        <div class="col text-left py-3">
          <img class="img-fluid logo ml-4" :src="logo" />
        </div>
      </div>
      <div class="row login-form-row d-flex align-items-center justify-content-center">
        <div class="col-lg-7 col-9
        bg-white d-flex align-items-center justify-content-center rounded form-div">
          <form class="text-center bg-white h-75 pt-4"
            v-on:submit.prevent
          >
            <template v-if="isRequestingAccess">
              <div class="form-row pt-4 pb-3">
                <div class="col">
                  <h3>
                    Request Access
                  </h3>
                </div>
              </div>
            </template>
            <template v-if="inStepEnterCode">
              <div class="form-row pt-4 pb-5 d-flex justify-content-center">
                <div class="col-10">
                  <input v-model="code"
                    :class="[errors ? 'is-invalid': '']"
                    type="text"
                    class="form-control form-control-sm py-4 px-3 text-center"
                    placeholder="OTP Code" />
                  <div class="invalid-feedback">
                    {{ errors }}
                  </div>
                </div>
              </div>
            </template>
            <template v-else-if="inStepEnterPhone">
              <div class="form-row pt-2 px-2 d-flex justify-content-center">
                <div class="col-10 px-0">
                  <label class="sr-only" for="countrySelect">Preference</label>
                  <select class="custom-select"
                    id="countrySelect"
                    v-model="selectCountry">
                    <option selected value="">Choose a country</option>
                    <option v-for="country in countries"
                      :key="country.alpha_2"
                      v-bind:value="country.alpha_2">
                      {{ `${country.name} (+${country.country_code})` }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-row pb-5 py-3 px-2 d-flex justify-content-center">
                <div class="col-10 px-0">
                  <input
                    v-model="phone"
                    :disabled="!selectCountry"
                    :class="[errors ? 'is-invalid': '']"
                    type="text"
                    class="form-control form-control-sm py-4 font-16 text-center font-weight-bold"
                    placeholder="Phone Number" />
                  <div class="invalid-feedback">
                    Invalid phone number
                  </div>
                </div>
              </div>
            </template>
            <template v-else-if="inStepEnterAccessDetails">
              <!-- <div class="form-row pt-2 px-2 d-flex justify-content-center">
                <div class="col-10 px-0">
                  <label class="sr-only" for="countrySelect">Country</label>
                  <select class="custom-select"
                    id="accessCountrySelect"
                    v-model="selectAccessCountry">
                    <option selected value="">Choose a country</option>
                    <option v-for="country in countries"
                      :key="country.alpha_2"
                      v-bind:value="country.alpha_2">
                      {{ `${country.name} (+${country.country_code})` }}
                    </option>
                  </select>
                </div>
              </div> -->
              <div class="form-row py-1 px-2 d-flex justify-content-center">
                <div class="col-5 pl-0">
                  <input
                    v-model="accessRequest.first_name"
                    type="text"
                    class="form-control form-control-sm py-4 font-16 text-center font-weight-bold"
                    :class="{'is-invalid': accessRequest.first_name_error}"
                    :disabled="isCreatingAccessRequest"
                    placeholder="First Name" />
                  <div class="invalid-feedback">
                    {{ accessRequest.first_name_error }}
                  </div>
                </div>
                <div class="col-5 pr-0">
                  <input
                    v-model="accessRequest.last_name"
                    type="text"
                    class="form-control form-control-sm py-4 font-16 text-center font-weight-bold"
                    :class="{'is-invalid': accessRequest.last_name_error}"
                    :disabled="isCreatingAccessRequest"
                    placeholder="Last Name" />
                  <div class="invalid-feedback">
                    {{ accessRequest.last_name_error }}
                  </div>
                </div>
              </div>
              <div class="form-row py-1 px-2 d-flex justify-content-center">
                <div class="col-10 px-0">
                  <input
                    v-model="accessRequest.email"
                    type="text"
                    class="form-control form-control-sm py-4 font-16 text-center font-weight-bold"
                    :class="{'is-invalid': accessRequest.email_error}"
                    :disabled="isCreatingAccessRequest"
                    placeholder="Email" />
                  <div class="invalid-feedback">
                    {{ accessRequest.email_error }}
                  </div>
                </div>
              </div>
              <div class="form-row py-1 px-2 d-flex justify-content-center">
                <div class="col-10 px-0">
                  <input
                    v-model="accessRequest.company"
                    type="text"
                    class="form-control form-control-sm py-4 font-16 text-center font-weight-bold"
                    :class="{'is-invalid': accessRequest.company_error}"
                    :disabled="isCreatingAccessRequest"
                    placeholder="Company" />
                  <div class="invalid-feedback">
                    {{ accessRequest.company_error }}
                  </div>
                </div>
              </div>
              <div class="form-row py-1 px-2 d-flex justify-content-center">
                <div class="col-10 px-0">
                  <input
                    v-model="accessRequest.phone"
                    type="text"
                    class="form-control form-control-sm py-4 font-16 text-center font-weight-bold"
                    placeholder="Phone Number" />

                  <!-- :class="{'is-invalid': accessRequest.phone_error}" -->
                  <!-- :disabled="!selectAccessCountry" -->
                  <!-- <div class="invalid-feedback">
                    {{ accessRequest.phone_error }}
                  </div> -->
                </div>
              </div>
            </template>

            <div class="form-row d-flex justify-content-center pt-4 pb-5">
              <div class="col-8 d-flex justify-content-center">
                <button
                  class="btn btn-lg otp-btn d-flex align-items-center justify-content-center py-3"
                  :disabled="isMainButtonDisabled"
                  @click.prevent="actionProcessForm"
                >
                  <div class="spinner-border spinner-border-sm mr-2" role="status" v-if="showSpinner">
                    <span class="sr-only">Loading...</span>
                  </div>
                  {{ buttonText }}
                </button>
              </div>
            </div>
            <div class="form-row"
              :class="[inStepEnterCode ? 'mt-5': 'mt-3']"
            >
              <div class="col d-flex justify-content-center">
                <template v-if="isRequestingAccess">
                  <button class="btn btn-sm request-btn d-flex align-items-center py-1"
                    @click="actionCancelAccessRequest"
                  >
                    <span class="material-icons">arrow_back</span>Back to Login
                  </button>
                </template>
                <template v-else>
                  <button class="btn btn-sm request-btn d-flex align-items-center py-1"
                    @click="actionBeginAccessRequest"
                  >
                    Request Access
                    <span class="material-icons">lock</span>
                  </button>
                </template>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="row terms-row">
        <div class="col text-center terms-of-use">
          <p class="title mb-2">
            Terms of use / privacy policy
          </p>
          <p class="body">
            (C) {{ currentYear }} WHAM App Sweden AB. All rights reserved.
            By continuing past this page, you agree to our <a :href="termsURL" class="text-light" target="_blank">terms of use</a>.
          </p>
        </div>
      </div>
    </div>
    <div class="col-lg-5 experience-column">
      <div class="row w-100">
        <div class="col-10 w-100">
          <p class="h-50 w-100 px-3">Tap into the world of experiences</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import _ from 'lodash';

export default {
  data() { return this.getInitialData(); },

  mounted() {
    this.getCountries();
  },

  computed: {
    ...mapState(['countries']),

    buttonText() {
      let text = 'Login';
      console.log(this.currentStep);
      switch (this.currentStep) {
        case this.stepEnterCode:
          text = 'VerifyOTP';
          break;

        case this.stepEnterAccessDetails:
          text = 'Request Access';
          break;

        default:
          //
      }
      return text;
    },

    isMainButtonDisabled() {
      let isDisabled = false;
      if (this.inStepEnterPhone) {
        isDisabled = !this.selectCountry
          || !this.phone
          || this.phone.length < 5
          || this.isRequestingOTP;
      } else if (this.inStepEnterCode) {
        isDisabled = !this.code
          || this.code.length < 5
          || this.isValidatingOTP;
      } else if (this.inStepEnterAccessDetails) {
        const keys = ['first_name', 'last_name', 'email', 'company'];
        isDisabled = this.isCreatingAccessRequest || Object.keys(this.accessRequest)
          .filter((k) => keys.indexOf(k) >= 0)
          .map((k) => {
            const value = this.accessRequest[k];
            return !!(value && value.trim());
          })
          .indexOf(false) >= 0;
      }
      return isDisabled;
    },

    isRequestingOTP() {
      return this.$store.getters['auth/isLoading']('otpRequest');
    },

    isCreatingAccessRequest() {
      return this.$store.getters['accessRequests/isLoading']('create');
    },

    showSpinner() {
      return this.isValidatingOTP || this.isRequestingOTP || this.isCreatingAccessRequest;
    },

    isValidatingOTP() {
      return this.$store.getters['auth/isLoading']('otpValidation');
    },

    inStepEnterPhone() {
      return this.currentStep === this.stepEnterPhone;
    },

    inStepEnterCode() {
      return this.currentStep === this.stepEnterCode;
    },

    inStepEnterAccessDetails() {
      return this.currentStep === this.stepEnterAccessDetails;
    },

    currentYear() {
      return new Date().getFullYear();
    },
  },

  methods: {
    getInitialData() {
      const stepEnterPhone = 'enterPhone';
      return {
        // eslint-disable-next-line global-require
        logo: require('@/assets/images/dashboard/logo_white.png'),
        code: '',
        phone: '',
        errors: '',
        selectCountry: '',
        invalidPhoneError: '',
        termsURL: 'policy.html',
        isRequestingAccess: false,
        stepEnterPhone,
        stepEnterCode: 'enterCode',
        stepEnterAccessDetails: 'enterAccessDetails',
        stepAccessDetailsSubmitted: 'detailsSubmitted',
        currentStep: stepEnterPhone,
        accessRequest: {
          first_name: '',
          last_name: '',
          email: '',
          company: '',
          phone: '',
        },
        pendingConfirmationMessage: 'This account is awaiting confirmation. An email shall be sent to you once you have passed the approval process',
      };
    },

    reset() {
      Object.assign(this.$data, this.getInitialData());
    },

    getCountries() {
      this.$store.dispatch('getCountries');
    },

    actionProcessForm() {
      console.debug('processing form');
      if (this.inStepEnterPhone) {
        console.debug(`phone is ${this.phone}`);
        const phone = this.validatePhone(this.phone);
        if (phone) {
          this.requestOTP(phone);
        }
      } else if (this.inStepEnterCode) {
        const code = this.validateCode(this.code);
        if (code) {
          this.verifyOTP(code);
        }
      } else if (this.inStepEnterAccessDetails) {
        const data = this.accessRequest;
        this.createAccessRequest(data);
      }
    },

    unpackErrors(errorValue) {
      let error = '';
      if (errorValue.constructor === Array) {
        error = errorValue.join('. ');
      } else {
        error = errorValue;
      }
      return error;
    },

    setFieldError(fieldName, value) {
      this.$set(this.errors, fieldName, value);
    },

    processAccessRequestErrors(errors) {
      const accessRequest = {};
      Object.keys(this.accessRequest)
        .filter((k) => !k.endsWith('_error'))
        .forEach((k) => {
          accessRequest[k] = this.accessRequest[k];
        });

      if (!_.isEmpty(errors)) {
        Object.keys(errors).forEach((key) => {
          const error = this.unpackErrors(errors[key]);
          const fieldName = `${key}_error`;
          accessRequest[fieldName] = error;
        });
      }
      this.accessRequest = accessRequest;
    },

    createAccessRequest(data) {
      console.debug('creatign access request');
      this.$store.dispatch(
        'accessRequests/createAccessRequest',
        data,
      )
        .then((response) => {
          const { success, errors } = response;
          if (success) {
            this.currentStep = this.stepAccessDetailsSubmitted;
          } else {
            this.processAccessRequestErrors(errors);
          }
        });
    },

    verifyOTP(code) {
      const { phone } = this;
      return this.$store.dispatch(
        'auth/verifyOTP',
        {
          phone,
          code,
        },
      )
        .then((response) => {
          console.debug('login successful');
          const { success, errors, data } = response;
          if (success) {
            if (this.isRequestingAccess) {
              if (data.access_token) {
                this.errors = 'Account already exists';
              } else {
                this.currentStep = this.stepEnterAccessDetails;
              }
            } else if (data.status) {
              // pending confirmation
              this.errors = this.pendingConfirmationMessage;
            } else {
              let { nextUrl } = this.$route.query;
              if (!nextUrl) {
                nextUrl = '/dashboard';
              }
              nextUrl = '/dashboard';
              console.debug(`next url is ${nextUrl}`);
              this.$router.push(nextUrl);
            }
          } else if (errors && errors.code && errors.code.code) {
            this.errors = errors.code.code;
          }
        });
    },

    requestOTP(phone) {
      return this.$store.dispatch(
        'auth/requestOTP',
        phone,
      )
        .then((response) => {
          console.debug('got api response');
          if (response.success) {
            this.currentStep = this.stepEnterCode;
            this.phone = phone;
            this.$set(this.accessRequest, 'phone', phone);
          } else {
            console.error('there was an error processing your request');
          }
        });
    },

    validatePhone(phone) {
      if (!phone || !phone.trim()) {
        this.errors = 'Invalid phone';
        return false;
      }
      const cleanPhone = phone.trim();
      if (!isValidPhoneNumber(cleanPhone, this.selectCountry)) {
        this.errors = 'Invalid phone';
        return false;
      }

      const phoneNumber = parsePhoneNumber(cleanPhone, this.selectCountry);
      if (!phoneNumber) {
        this.errors = 'Invalid phone';
        return false;
      }

      return phoneNumber.number;
    },

    validateCode(code) {
      if (!code || !code.trim()) {
        this.errors = 'Invalid code';
        return false;
      }

      return code.trim();
    },

    actionBeginAccessRequest() {
      this.reset();
      this.isRequestingAccess = true;
      this.currentStep = this.stepEnterAccessDetails;
    },

    actionCancelAccessRequest() {
      this.reset();
    },
  },
};
</script>

<style lang="scss">
.login-container {
  min-height: 100vh;
}

.terms-of-use {
  text-transform: uppercase;
  color: $wham-sec-light-color;
  font-weight: bold;

  .title {
    font-size: 10px;
  }

  .body {
    font-size: 8px;
  }
}

.form-panel {
  min-height: 100vh;
  background-color: $wham-primary-color;

  .image-row, .terms-row {
    height: 10%;

    .logo {
      max-width: 120px;
    }
  }

  .login-form-row {
    height: 80%;

    .form-div {
      padding-left: 40px;
      padding-right: 40px;

      form {
        .form-group {
          padding-top: 50px;
        }
      }
    }

    .form-div {
      height: 90%;
      border-radius: 5px !important;
    }

  }

  @media (max-width: 994px) {

    .image-row > .col, .ml-4{
      text-align: center !important;
      margin-left: 0 !important;
    }

    .login-form-row {

      .form-div {
        display: block !important;
        padding-top: 20px;
        padding-left: 10px;
        padding-right: 10px;

        form {
          .form-group {
            padding-top: 20px;
          }
        }

        .form-row {
          // display: block !important;
          margin: auto;
        }
      }
    }
  }

}

button.otp-btn {
  text-transform: uppercase;
  border-radius: 0 !important;
  font-size: 13px !important;
  width: 90%;
}

.request-btn span {
  font-size: 16px;
}

.image-row span {
  font-size: 32px;
  font-family: $font-roboto;
  font-weight: 700;
  color: $wham-sec-light-color;
  padding-left: 15px;
}

.experience-column {
  min-height: 100vh;

  background-image: url('~@/assets/images/login/hot-air-balloon.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;

  &:before {
    content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(to bottom right,#0f1011,rgba(255,0,0,0));
        opacity: .6;
  }

  p {
    position: relative;
    background: rgba(204, 204, 204, 0);
    color: $wham-sec-light-color;
    font-size: 48px;
    font-weight: 700;
    line-height: 1.2;
    text-transform: uppercase;
    top: 13%;
  }

  @media (max-width: 994px) {
    display: none;
  }

}
</style>
